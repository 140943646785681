<div id="sectionId_umbaukosten"></div>
<div *transloco="let t" class="sectionId_umbaukosten sectionAnchor flexBoxOuter">
  <div class="flexBoxInner txt marginRight" style="hyphens: auto">
    <div style="margin-bottom: 26px">
      <h4 data-translate="start.quickNav.rebuild">{{ t("products.start.quickNav.rebuild") }}</h4>
    </div>

    <div style="margin-top: 33px">
      <h3 data-translate="umbaukosten.title">{{ t("products.umbaukosten.title") }}</h3>
    </div>
    <div style="margin-top: 20px">
      <p
        class="brightfont"
        style="display: block; font-weight: bold; margin-bottom: 0"
        data-translate="umbaukosten.text-1-header"
      >
        {{ t("products.umbaukosten.text-1-header") }}
      </p>
      <p class="brightfont" style="display: inline" data-translate="umbaukosten.text-1-1">
        {{ t("products.umbaukosten.text-1-1") }}
      </p>

      <p style="display: inline; hyphens: auto" class="brightfont" data-translate="umbaukosten.text-1-2">
        {{ t("products.umbaukosten.text-1-2") }}
      </p>
      <p style="display: inline; hyphens: auto" class="brightfont" data-translate="umbaukosten.text-1-3">
        {{ t("products.umbaukosten.text-1-3") }}
      </p>
    </div>

    <p
      class="brightfont"
      style="margin-top: 10px; display: block; font-weight: bold"
      data-translate="umbaukosten.text-2-header"
    >
      {{ t("products.umbaukosten.text-2-header") }}
    </p>

    <p class="brightfont" style="margin-bottom: 10px" data-translate="products.text-2">
      {{ t("products.products.text-2") }}
    </p>
    <div style="margin-bottom: 20px">
      <button
        *ngIf="!isAuthenticated"
        ui-sref="register"
        class="btn btn-elegant-black kw-btn-grid"
        id="rebuildCostsAndDates"
        [routerLink]="'7tage'"
      >
        <span data-translate="use_free_month">{{ t("products.use_free_month") }}</span>
      </button>
      <ng-container *ngIf="isAuthenticated">
        <button
          *ngIf="hasRebuildCosts"
          ui-sref="rebuild-form.input({withdrawalProductType: 'rebuild_costs'})"
          data-ui-sref-opts="{reload: 'operational-costs'}"
          class="btn btn-elegant-black kw-btn-grid"
          (click)="goToRebuildForm()"
        >
          <mat-icon class="material-symbols-outlined" style="font-size: 20px; vertical-align: middle">
            play_arrow </mat-icon
          >&nbsp;
          <span>{{ t("products.calc_now") }}</span>
        </button>
        <button
          *ngIf="!hasRebuildCosts"
          (click)="openBillingModalForRebuild()"
          class="btn btn-elegant-black kw-btn-grid"
        >
          <i class="fa fa-credit-card" style="height: 16px; width: 16px"></i>&nbsp;
          <span data-translate="get_license">{{ t("products.get_license") }}</span>
        </button>
      </ng-container>
      <!--                    <a class="btn btn-elegant-black kw-btn-grid" ui-sref="rebuild-accuracy" target="_blank" data-translate="learn_more">-->
      <!--                        mehr erfahren-->
      <!--                    </a>-->

      <!--                    <button class="btn btn-elegant-black kw-btn-grid" ui-sref="products.video">-->
      <!--                        <i class="fa fa-youtube-play"></i>&nbsp;-->
      <!--                        <span data-translate="umbaukosten.name">Der Neubaukostenrechner</span>-->
      <!--                    </button>-->

      <a
        class="btn btn-elegant-black kw-btn-grid"
        href="../../../../pdf/{{ '' | currentLanguage }}/keeValue_AG-Flyer.pdf"
        target="_blank"
      >
        <img src="/assets/images/file-pdf.svg" width="16" class="file-pdf align-icon" />&nbsp;
        <span data-translate="flyer">{{ t("products.flyer") }}</span>
      </a>
    </div>
  </div>

  <div class="flexBoxInner img">
    <div class="centerImage centerImgVertically">
      <img src="/assets/images/products/rebuild_costs_BG.svg" style="align-self: center" />
    </div>
  </div>
</div>
