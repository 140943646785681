<div id="sectionId_lcc"></div>
<div *transloco="let t" class="sectionId_lcc sectionAnchor flexBoxOuter">
  <div class="flexBoxInner txt marginRight" style="hyphens: auto">
    <div style="margin-bottom: 26px">
      <h4 data-translate="start.quickNav.lcc">{{ t("products.start.quickNav.lcc") }}</h4>
    </div>

    <div style="margin-top: 33px">
      <h3 data-translate="lcc.title">{{ t("products.lcc.title") }}</h3>
    </div>
    <div class="brightfont" style="margin-top: 20px">
      <p style="font-weight: bold" data-translate="lcc.text-1-header" [innerHTML]="t('products.lcc.text-1-header')"></p>
      <p data-translate="lcc.text-1-1">{{ t("products.lcc.text-1-1") }}</p>
      <p style="margin-top: 10px" data-translate="lcc.text-1-2" [innerHTML]="t('products.lcc.text-1-2')"></p>
    </div>

    <p
      class="brightfont"
      style="margin-top: 10px; display: block; font-weight: bold"
      data-translate="lcc.text-2-header"
    >
      {{ t("products.lcc.text-2-header") }}
    </p>

    <p class="brightfont" style="margin-bottom: 10px" data-translate="products.text-2">
      {{ t("products.products.text-2") }}
    </p>

    <!--    <div style="margin-bottom: 20px">-->
    <!--      <button ui-sref="register" class="btn btn-elegant-black kw-btn-grid" id="buildingCostsAndDates" disabled>-->
    <!--        <span>coming soon</span>-->
    <!--      </button>-->
    <!--    </div>-->
    <div style="margin-bottom: 20px">
      <button
        *ngIf="!isAuthenticated"
        ui-sref="register"
        class="btn btn-elegant-black kw-btn-grid"
        id="rebuildCostsAndDates"
        [routerLink]="'7tage'"
      >
        <span data-translate="use_free_month">{{ t("products.use_free_month") }}</span>
      </button>
      <ng-container *ngIf="isAuthenticated">
        <button
          *ngIf="hasLccCosts"
          ui-sref="rebuild-form.input({withdrawalProductType: 'rebuild_costs'})"
          data-ui-sref-opts="{reload: 'operational-costs'}"
          class="btn btn-elegant-black kw-btn-grid"
          (click)="goToLccForm()"
        >
          <mat-icon class="material-symbols-outlined" style="font-size: 20px; vertical-align: middle">
            play_arrow </mat-icon
          >&nbsp;
          <span>{{ t("products.calc_now") }}</span>
        </button>
        <button *ngIf="!hasLccCosts" (click)="openModal()" class="btn btn-elegant-black kw-btn-grid">
          <i class="fa fa-credit-card" style="height: 16px; width: 16px"></i>&nbsp;
          <span data-translate="get_license">{{ t("products.get_license") }}</span>
        </button>
      </ng-container>
      <a
        class="btn btn-elegant-black kw-btn-grid"
        ui-sref="accuracy_operating_costs"
        target="_blank"
        data-translate="learn_more"
        [routerLink]="'info-operating-costs'"
      >
        {{ t("products.learn_more") }}
      </a>
      <a
        class="btn btn-elegant-black kw-btn-grid"
        href="../../../../pdf/{{ '' | currentLanguage }}/keeValue_AG-Flyer.pdf"
        target="_blank"
      >
        <img src="/assets/images/file-pdf.svg" width="16" class="file-pdf align-icon" />&nbsp;
        <span data-translate="flyer">{{ t("products.flyer") }}</span>
      </a>
    </div>
  </div>

  <div class="flexBoxInner img">
    <div class="centerImage centerImgVertically">
      <img src="/assets/images/products/lebenszyklus_BG.svg" style="align-self: center" />
    </div>
  </div>
</div>
