import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { VideoDetailsService } from '@app/core/services/video-details.service';
import { WithdrawalProductType } from '@generated/generatedEntities';

@Component({
  selector: 'app-pf',
  templateUrl: './pf.component.html',
  styleUrls: ['./pf.component.scss'],
})
export class PfComponent implements OnInit {
  private videoDetailService = inject(VideoDetailsService);
  private router = inject(Router);
  @Input() isAuthenticated!: boolean;
  @Input() hasModule!: boolean;
  @Output() openBillingModal: EventEmitter<WithdrawalProductType> = new EventEmitter();

  ngOnInit(): void {}

  // openBillingModalForBuild() {
  //   this.openBillingModal.emit('BUILDING_COSTS_AND_DATES');
  // }
  // openVideoDialog(): void {
  //   this.videoDetailService.openVideoDialog();
  // }

  // goToInfo() {
  //   this.router.navigate([]).then((result) => {
  //     window.open('/info', '_blank');
  //   });
  // }

  goToForm() {
    this.router.navigate(['/planning-foundation-form/input']);
  }
}
