<div id="sectionId_dienstleistungen"></div>
<div
  *transloco="let t"
  class="sectionId_dienstleistungen sectionAnchor flexBoxOuter"
  style="padding: 50px 20px; background: #e8e8e8"
>
  <div class="flexBoxInner img marginRight">
    <div class="centerImage centerImgVertically">
      <img src="/assets/images/products/dienstleistungen_BG.svg" style="align-self: center" />
    </div>
  </div>

  <div class="flexBoxInner txt" style="hyphens: auto">
    <div style="margin-bottom: 26px">
      <h4 data-translate="start.quickNav.services">{{ t("products.start.quickNav.services") }}</h4>
    </div>

    <div style="margin-top: 20px">
      <h3 data-translate="services.title">{{ t("products.services.title") }}</h3>
    </div>

    <div class="brightfont">
      <p style="margin-top: 20px" data-translate="services.text-1">{{ t("products.services.text-1") }}</p>
      <div style="margin-top: 20px">
        <a class="btn btn-elegant-black" href="mailto:info@keevalue.ch">
          <img src="/assets/images/envelope.svg" style="vertical-align: sub" class="envelope" width="18" />&nbsp;
          <span>{{ t("global.ask-us") }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
