<div id="sectionId_references"></div>
<div
  *transloco="let t"
  class="sectionId_references sectionAnchor flexBoxOuter"
  style="padding: 50px 20px; background: #fdfdfe"
>
  <div class="flexBoxInnerWider txt marginRight" style="hyphens: auto">
    <div style="margin-bottom: 26px">
      <h4 data-translate="start.quickNav.references">
        {{ t("products.start.quickNav.references") }}
      </h4>
    </div>
    <div class="kw-reference-row">
      <span class="kw-reference-card">
        <a href="https://www.baulink.ch" rel="noopener" target="_blank">
          <img style="width: 60%" src="/assets/images/references/baulink.svg" alt="Baulink" />
        </a>
      </span>
      <span class="kw-reference-card">
        <a href="https://bauart.ch/" rel="noopener" target="_blank">
          <img style="width: 55%" src="/assets/images/references/bauart.png" alt="Bauart" />
        </a>
      </span>
      <span class="kw-reference-card">
        <a href="https://carlosmartinez.ch/" rel="noopener" target="_blank">
          <img style="width: 90%" src="/assets/images/references/cm.jpg" alt="Carlos Martinez Architekten" />
        </a>
      </span>
      <span class="kw-reference-card">
        <a href="https://www.fws-arch.ch/" rel="noopener" target="_blank">
          <img style="width: 50%; padding-bottom: 30px" src="/assets/images/references/fws.png" alt="FWS" />
        </a>
      </span>
      <span class="kw-reference-card">
        <a href="https://fnp-architekten.ch/" rel="noopener" target="_blank">
          <img
            style="width: 90%"
            src="/assets/images/references/flubacher_nyfler_partner.png"
            alt="flubacher nyfler partner"
          />
        </a>
      </span>
    </div>

    <div style="margin-top: 20px" class="kw-reference-row">
      <span class="kw-reference-card">
        <a href="https://www.gwj.ch/" rel="noopener" target="_blank">
          <img style="width: 45%" src="/assets/images/references/gwj-2.png" alt="gwj" />
        </a>
      </span>
      <span class="kw-reference-card">
        <a href="https://halter-hunziker.ch/" rel="noopener" target="_blank">
          <img style="width: 75%" src="/assets/images/references/halter-hunziker.svg" alt="Halter Hunziker" />
        </a>
      </span>
      <span class="kw-reference-card">
        <a href="https://www.helbling.ch/" rel="noopener" target="_blank">
          <img style="width: 75%" src="/assets/images/references/helbling.svg" alt="Helbling" />
        </a>
      </span>

      <span class="kw-reference-card">
        <a href="https://oos.com/" rel="noopener" target="_blank">
          <img style="width: 45%" src="/assets/images/references/oos.svg" alt="OOS" />
        </a>
      </span>

      <span class="kw-reference-card">
        <a href="https://www.rapp.ch/de" rel="noopener" target="_blank">
          <img style="width: 65%" src="/assets/images/references/rapp.svg" alt="Rapp" />
        </a>
      </span>
    </div>

    <div style="margin-top: 10px" class="kw-reference-row">
      <span class="kw-reference-card">
        <a href="https://reasco.immo" rel="noopener" target="_blank">
          <img style="width: 70%" src="/assets/images/references/reasco.png" alt="Reasco Immobilien" />
        </a>
      </span>

      <span class="kw-reference-card">
        <a href="https://www.rlc.ch/" rel="noopener" target="_blank">
          <img style="width: 80%" src="/assets/images/references/rlc.svg" alt="RLC" />
        </a>
      </span>

      <span class="kw-reference-card">
        <a href="https://scheitlin-syfrig.ch/" rel="noopener" target="_blank">
          <img style="width: 90%" src="/assets/images/references/scheitlinsyfrig.png" alt="Scheitlin Syfrig" />
        </a>
      </span>

      <span class="kw-reference-card">
        <a href="https://tgsbauoekonomen.ch/" rel="noopener" target="_blank">
          <img style="width: 75%" src="/assets/images/references/tgs_bauokonomen.svg" alt="TGS bauökonomen" />
        </a>
      </span>

      <span class="kw-reference-card">
        <a href="https://www.wthommen.ch/" rel="noopener" target="_blank">
          <img style="width: 75%" src="/assets/images/references/thommen.svg" alt="wThommen" />
        </a>
      </span>
    </div>

    <div style="margin-top: 10px" class="kw-reference-row">
      <span class="kw-reference-card">
        <a href="https://weberbrunner.eu/" rel="noopener" target="_blank">
          <img style="width: 95%" src="/assets/images/references/weberbrunner.svg" alt="Weberbrunner" />
        </a>
      </span>

      <span class="kw-reference-card">
        <a href="http://www.zachzuend.ch/" rel="noopener" target="_blank">
          <img style="width: 75%" src="/assets/images/references/znz.png" alt="Zach + Zünd" />
        </a>
      </span>

      <span class="kw-reference-card">
        <a href="https://www.allianz.ch" rel="noopener" target="_blank">
          <img style="width: 75%" src="/assets/images/references/allianz.svg" alt="Allianz" />
        </a>
      </span>

      <span class="kw-reference-card">
        <a href="https://www.lukb.ch" rel="noopener" target="_blank">
          <img style="width: 80%" src="/assets/images/references/lukb.svg" alt="LUKB" />
        </a>
      </span>
      <span class="kw-reference-card">
        <a href="https://www.previs.ch" rel="noopener" target="_blank">
          <img style="width: 75%" src="/assets/images/references/previs.svg" alt="Previs" />
        </a>
      </span>
    </div>

    <div style="padding-top: 10px" class="kw-reference-row">
      <span class="kw-reference-card">
        <a
          href="https://www.liestal.ch/de/verwaltung/departementered/alledepartemente/departemente/welcome.php?departement_id=42&page=0"
          rel="noopener"
          target="_blank"
        >
          <img
            style="width: 75%"
            src="/assets/images/references/stadt-liestal-stadtbauamt.png"
            alt="Stadt Liestal Stadtbauamt"
          />
        </a>
      </span>
      <span class="kw-reference-card">
        <a href="http://www.stadt-schaffhausen.ch/" rel="noopener" target="_blank">
          <img style="width: 75%" src="/assets/images/references/stadt-schaffhausen.png" alt="Stadt Schaffhausen" />
        </a>
      </span>

      <span class="kw-reference-card">
        <a href="https://www.steiner.ch" rel="noopener" target="_blank">
          <img style="width: 75%" src="/assets/images/references/steiner.svg" alt="Steiner" />
        </a>
      </span>

      <span class="kw-reference-card">
        <a href="https://www.walo.ch/" rel="noopener" target="_blank">
          <img style="max-height: 60%" src="/assets/images/references/walo.svg" alt="Walo" />
        </a>
      </span>

      <span class="kw-reference-card">
        <a
          href="https://www.ubs.com/ch/de/asset-management/institutional-investors/products/white-labelling-solutions/fund-management-company-services.html"
          rel="noopener"
          target="_blank"
        >
          <img
            style="max-height: 24%"
            src="/assets/images/references/ubs.svg"
            alt="UBS Fund Management (Switzerland) AG"
          />
        </a>
      </span>
    </div>
  </div>
</div>
