<div id="sectionId_betriebskosten"></div>
<div *transloco="let t" class="sectionId_betriebskosten sectionAnchor flexBoxOuter">
  <div class="flexBoxInner txt marginRight" style="hyphens: auto">
    <div style="margin-bottom: 26px">
      <h4 data-translate="start.quickNav.bewk">{{ t("products.start.quickNav.bewk") }}</h4>
    </div>

    <div style="margin-top: 33px">
      <h3 data-translate="bewk.title">{{ t("products.bewk.title") }}</h3>
    </div>
    <div style="margin-top: 20px">
      <p
        class="brightfont"
        style="display: block; font-weight: bold"
        [innerHTML]="t('products.bewk.text-1-header')"
      ></p>

      <p
        class="brightfont"
        style="display: inline; hyphens: auto"
        data-translate="bewk.text-1-1"
        [innerHTML]="t('products.bewk.text-1-1')"
      ></p>
      <p
        class="brightfont"
        style="display: inline; hyphens: auto"
        data-translate="bewk.text-1-2"
        [innerHTML]="t('products.bewk.text-1-2')"
      ></p>
    </div>

    <p
      class="brightfont"
      style="margin-top: 10px; display: block; font-weight: bold"
      data-translate="bewk.text-2-header"
    >
      {{ t("products.bewk.text-2-header") }}
    </p>

    <p class="brightfont" style="margin-bottom: 10px" data-translate="products.text-2">
      {{ t("products.products.text-2") }}
    </p>
    <div style="margin-bottom: 20px">
      <button
        *ngIf="!isAuthenticated"
        ui-sref="register"
        class="btn btn-elegant-black kw-btn-grid"
        id="buildingCostsAndDates2"
        data-translate="use_free_month"
        [routerLink]="'7tage'"
      >
        {{ t("products.use_free_month") }}
      </button>
      <ng-container *ngIf="isAuthenticated">
        <button
          *ngIf="hasOperationalCosts"
          ui-sref="operational-costs.input({withdrawalProductType: 'operating_costs'})"
          data-ui-sref-opts="{reload: 'operational-costs'}"
          class="btn btn-elegant-black kw-btn-grid"
          (click)="goToOperationalForm()"
        >
          <mat-icon class="material-symbols-outlined" style="font-size: 20px; vertical-align: middle">
            play_arrow </mat-icon
          >&nbsp;
          <span>{{ t("products.calc_now") }}</span>
        </button>
        <button *ngIf="!hasOperationalCosts" (click)="openModal()" class="btn btn-elegant-black kw-btn-grid">
          <i class="fa fa-credit-card" style="height: 16px; width: 16px"></i>&nbsp;
          <span data-translate="get_license">{{ t("products.get_license") }}</span>
        </button>
      </ng-container>
      <a
        class="btn btn-elegant-black kw-btn-grid"
        ui-sref="accuracy_operating_costs"
        target="_blank"
        data-translate="learn_more"
        [routerLink]="'info-operating-costs'"
      >
        {{ t("products.learn_more") }}
      </a>

      <button class="btn btn-elegant-black kw-btn-grid" ui-sref="products.video-pom" (click)="openVideoPom()">
        <img src="/assets/images/youtube2.svg" class="align-icon" width="16" />&nbsp;
        <span data-translate="bewk.name">{{ t("products.bewk.name") }}</span>
      </button>

      <a href="https://www.pom.ch" target="_blank">
        <div style="display: inline-block; height: 100%" class="btn btn-elegant-black kw-btn-grid">
          <span>
            <span style="font-size: 12px" class="brightfont" data-translate="bewk.powered">
              {{ t("products.bewk.powered") }}</span
            ><img
              style="
                max-height: 21px;
                vertical-align: bottom;
                padding-left: 4px;
                overflow: visible;
                display: inline;
                cursor: pointer;
              "
              src="assets/images/partners/logo_pom.svg"
              alt="logo_pom"
            />
          </span>
        </div>
      </a>
    </div>
  </div>

  <div class="flexBoxInner img">
    <div class="centerImage centerImgVertically">
      <img src="/assets/images/products/betriebskosten_BG.svg" style="align-self: center" />
    </div>
  </div>
</div>
