<!-- <div id='sectionId_webinar'></div> -->

<div
  *transloco="let t"
  #sectionId_webinar
  id="sectionId_webinar"
  class="rowBackgroundRed sectionId_webinar sectionAnchor flexBoxOuter"
>
  <div class="flexBoxInner txt marginRight" style="hyphens: auto">
    <div class="rowBackgroundRed" style="margin-bottom: 0px">
      <div style="display: inline">
        <h3
          class="kennwertered"
          style="color: white; display: inline-block; margin-right: 0px; margin-top: 0; margin-bottom: 0px"
          data-translate="webinar.header"
        >
          {{ t("products.webinar.header") }}
        </h3>
      </div>
      <div class="newsBox">
        <ul style="list-style-type: none">
          <li style="color: white; padding-top: 10px; width: 100%">
            <span class="newsBoxButtonText" data-translate="webinar.comingsoon">{{
              t("products.webinar.comingsoon")
            }}</span>
          </li>

          <!--          <li style="color: white; padding-top: 10px; width: 100%">-->
          <!--            <div style="width: 69%; display: inline-flex">-->
          <!--              <span>{{ t("products.webinar.rebuild-costs", { date: "29.11.2023" }) }}</span>-->
          <!--            </div>-->
          <!--            <a-->
          <!--              class="btn btn-elegant-white newsBoxButton"-->
          <!--              rel="noopener"-->
          <!--              target="_blank"-->
          <!--              href="https://www.sia.ch/fr/services/sia-service/explore/detail/event/8307/"-->
          <!--              style="vertical-align: baseline"-->
          <!--            >-->
          <!--              <span>{{ t("products.webinar.more-info") }}</span>-->
          <!--              <span class="newsBoxButtonText2">{{ t("products.webinar.info") }}</span>-->
          <!--            </a>-->
          <!--          </li>-->
          <!--            <li style="color: white; padding-top: 10px; width: 100%">-->
          <!--            <div style="width: 69%; display: inline-flex">-->
          <!--              <span>{{ t('products.webinar.building-costs', { date: '27.09.2023' }) }}</span>-->
          <!--            </div>-->
          <!--            <a-->
          <!--              class="btn btn-elegant-white newsBoxButton"-->
          <!--              rel="noopener"-->
          <!--              target="_blank"-->
          <!--              href="https://www.sia.ch/fr/services/sia-service/explore/detail/event/8304/"-->
          <!--              style="vertical-align: baseline"-->
          <!--            >-->
          <!--              <span>{{ t('products.webinar.more-info') }}</span>-->
          <!--              <span class="newsBoxButtonText2">{{ t('products.webinar.info') }}</span>-->
          <!--            </a>-->
          <!--          </li>-->
        </ul>
      </div>
    </div>
  </div>
  <!-- <div class="flexBoxInner img">
         <div class="centerImage centerImgVertically" style="position: relative; text-align: center;">
             <a ng-cloak class="swissbau" href="https://www.swissbau.ch/fr/swissbau-innovation-lab" target="_blank"
                rel="noopener">
                 <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                     <style>circle {
                         fill: white;
                         fill-opacity: 0.1;
                         stroke: white;
                         stroke-width: 2px;
                     }
                     </style>
                     <circle class="white-hover-bg" cx="250" cy="250.4" r="241.6"/>
                 </svg>
                 <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                     <span class="swissbau1" data-translate="show.swissbau">Wir sind an </span> <span class="swissbau1-1"
                                                                                                      data-translate="show.swissbau-1">der Swissbau</span><br/><span
                     class="swissbau2"
                     data-translate="show.swissbau-2">3. - 6. Mai</span><br/><span data-translate="show.swissbau-3"
                                                                                        class="swissbau3">Stand L82 InnovationLab</span>
             </span>
             </a>
         </div>
     </div>-->
  <div class="flexBoxInner img">
    <!--    DRS-->
    <!--    <div class="centerImage centerImgVertically" style="position: relative; text-align: center">-->
    <!--      <a-->
    <!--        ng-cloak-->
    <!--        class="swissbau"-->
    <!--        href="https://www.digitalrealestate.ch/digital-real-estate-summit/"-->
    <!--        target="_blank"-->
    <!--        rel="noopener"-->
    <!--      >-->
    <!--        <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">-->
    <!--          <style>-->
    <!--            circle {-->
    <!--              fill: white;-->
    <!--              fill-opacity: 0.1;-->
    <!--              stroke: white;-->
    <!--              stroke-width: 2px;-->
    <!--            }-->
    <!--          </style>-->
    <!--          <circle class="white-hover-bg" cx="250" cy="250.4" r="241.6" />-->
    <!--        </svg>-->
    <!--        <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">-->
    <!--          <span class="swissbau1" data-translate="show.drs">{{ t('products.show.drs') }}</span-->
    <!--          ><br /><span class="swissbau1-1" data-translate="show.drs-1">{{ t('products.show.drs-1') }}</span-->
    <!--          ><br /><span class="swissbau2" data-translate="show.drs-2">{{ t('products.show.drs-2') }}</span> <br /><span-->
    <!--            class="swissbau3"-->
    <!--            data-translate="show.drs-3"-->
    <!--            >{{ t('products.show.drs-3') }}</span-->
    <!--          >-->
    <!--        </span>-->
    <!--      </a>-->
    <!--    </div>-->

    <!--    SOCIALS Default-->
    <!--             <div-->
    <!--                class="centerImage centerImgVertically"-->
    <!--                style="position: relative; text-align: center;"-->
    <!--            >-->
    <!--                <span-->
    <!--                    ng-cloak-->
    <!--                    class="social"-->
    <!--                >-->
    <!--                    <svg-->
    <!--                        id="Ebene_1"-->
    <!--                        xmlns="http://www.w3.org/2000/svg"-->
    <!--                        viewBox="0 0 500 500"-->
    <!--                    >-->
    <!--                        <style>-->
    <!--                            circle {-->
    <!--                                fill: white;-->
    <!--                                fill-opacity: 0.1;-->
    <!--                                stroke: white;-->
    <!--                                stroke-width: 2px;-->
    <!--                            }-->
    <!--                        </style>-->
    <!--                        <circle-->
    <!--                            class="white-hover-bg"-->
    <!--                            cx="250"-->
    <!--                            cy="250.4"-->
    <!--                            r="241.6"-->
    <!--                        />-->
    <!--                    </svg>-->

    <!--                    <span-->
    <!--                        style="-->
    <!--                            position: absolute;-->
    <!--                            top: 45%;-->
    <!--                            left: 50%;-->
    <!--                            transform: translate(-50%, -50%);-->
    <!--                        "-->
    <!--                    >-->
    <!--                        <span class="social1" data-translate="show.facebook"-->
    <!--                            >Wir sind auf</span-->
    <!--                        ><br /><a href="https://www.facebook.com/kennwerteadmin/" target="_blank" rel="noopener"  class="social-1-1" data-translate="show.facebook-1">Facebook</a>-->

    <!--                        <br /><span class="social2" data-translate="show.facebook-2"-->
    <!--                            >und</span-->
    <!--                        >-->
    <!--                        <br /><a href="https://ch.linkedin.com/company/kennwerte-ag" target="_blank" rel="noopener" class="social3"  data-translate="show.facebook-3"></a>-->

    <!--                    </span>-->
    <!--                </span>-->
    <!--            </div>-->

    <!--    CO2-->
    <div class="centerImage centerImgVertically" style="position: relative; text-align: center">
      <a ng-cloak class="swissbau" href="pdf/{{ '' | currentLanguage }}/keeValue_CO2.pdf" target="_blank">
        <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <style>
            circle {
              fill: white;
              fill-opacity: 0.1;
              stroke: white;
              stroke-width: 2px;
            }
          </style>
          <circle class="white-hover-bg" cx="250" cy="250.4" r="241.6" />
        </svg>
        <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
          <span class="co2-1">CO<sub>2</sub></span
          ><br /><span class="swissbau1-1" data-translate="show.co2-2">{{ t("products.show.co2-2") }}</span
          ><br /><span class="co2-3" data-translate="show.co2-3">{{ t("products.show.co2-3") }}</span>
        </span>
      </a>
    </div>
  </div>
</div>
