import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebsiteFragmentsRoutingModule } from './website-fragments-routing.module';
import { AboutUsComponent } from './about-us/about-us.component';
import { AdvisoryBoardComponent } from './advisory-board/advisory-board.component';
import { DynamicWebinarComponent } from './products/dynamic-webinar/dynamic-webinar.component';
import { WebinarDeComponent } from './products/webinar-de/webinar-de.component';
import { WebinarFrComponent } from './products/webinar-fr/webinar-fr.component';
import { BewkComponent } from './products/bewk/bewk.component';
import { BuildComponent } from './products/build/build.component';
import { InsuranceComponent } from './products/insurance/insurance.component';
import { IntroComponent } from './products/intro/intro.component';
import { LccComponent } from './products/lcc/lcc.component';
import { RebuildComponent } from './products/rebuild/rebuild.component';
import { ServicesComponent } from './products/services/services.component';
import { WebinarComponent } from './products/webinar/webinar.component';
import { MaterialModule } from '@app/material.module';
import { TranslocoModule } from '@jsverse/transloco';
import { ReferenceComponent } from './about-us/reference/reference.component';
import { TeamComponent } from './about-us/team/team.component';
import { AnimatedDigitModule } from '@app/animated/animated-digit/animated-digit.module';
import { PricesComponent } from './prices/prices.component';
import { RegisterFormModule } from '@app/register-form/register-form.module';
import { I18nModule } from '@app/i18n';
import { LcaComponent } from '@app/website_fragments/products/lca/lca.component';
import { PfComponent } from '@app/website_fragments/products/pf/pf.component';

@NgModule({
  declarations: [
    AboutUsComponent,
    AdvisoryBoardComponent,
    DynamicWebinarComponent,
    WebinarDeComponent,
    WebinarFrComponent,
    BewkComponent,
    BuildComponent,
    InsuranceComponent,
    IntroComponent,
    LcaComponent,
    LccComponent,
    PfComponent,
    RebuildComponent,
    ServicesComponent,
    WebinarComponent,
    ReferenceComponent,
    TeamComponent,
    PricesComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslocoModule,
    WebsiteFragmentsRoutingModule,
    AnimatedDigitModule,
    RegisterFormModule,
    I18nModule,
  ],
  exports: [
    AboutUsComponent,
    AdvisoryBoardComponent,
    DynamicWebinarComponent,
    WebinarDeComponent,
    WebinarFrComponent,
    BewkComponent,
    BuildComponent,
    InsuranceComponent,
    IntroComponent,
    LcaComponent,
    PfComponent,
    LccComponent,
    RebuildComponent,
    ServicesComponent,
    WebinarComponent,
    ReferenceComponent,
    TeamComponent,
    PricesComponent,
  ],
})
export class WebsiteFragmentsModule {}
