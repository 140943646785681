import { Component, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss'],
})
export class InsuranceComponent implements OnInit {
  private router = inject(Router);
  @Input() isAuthenticated!: boolean;
  @Input() hasInsuranceCosts!: boolean;

  ngOnInit(): void {}

  goToInsuranceForm() {
    this.router.navigate(['/insurance-form/input']);
  }
}
