import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { VideoDetailsService } from '@app/core/services/video-details.service';
import { WithdrawalProductType } from '@generated/generatedEntities';

@Component({
  selector: 'app-build',
  templateUrl: './build.component.html',
  styleUrls: ['./build.component.scss'],
})
export class BuildComponent implements OnInit {
  private videoDetailService = inject(VideoDetailsService);
  private router = inject(Router);
  @Input() IsAuthenticated!: boolean;
  @Input() hasBuildingCosts!: boolean;
  @Output() openBillingModal: EventEmitter<WithdrawalProductType> = new EventEmitter();

  ngOnInit(): void {
    console.warn(this.hasBuildingCosts);
  }

  openBillingModalForBuild() {
    this.openBillingModal.emit('BUILDING_COSTS_AND_DATES');
  }
  openVideoDialog(): void {
    this.videoDetailService.openVideoDialog();
  }

  goToInfo() {
    this.router.navigate([]).then((result) => {
      window.open('/info', '_blank');
    });
  }

  goToProcessForm() {
    this.router.navigate(['/process-form/input']);
  }
}
