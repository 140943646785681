import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webinar-fr',
  templateUrl: './webinar-fr.component.html',
  styleUrls: ['./webinar-fr.component.scss'],
})
export class WebinarFrComponent implements OnInit {
  ngOnInit(): void {}
}
