import { Injectable, inject } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Logger } from '@shared';
import { I18nService } from '@app/i18n';

const log = new Logger('LanguageRoutingResolver');

@Injectable({
  providedIn: 'root',
})
export class LanguageRoutingResolver {
  private i18nService = inject(I18nService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
    var language = null;
    if (route.url.length > 0 && route.url[0].path == 'fr') {
      language = 'fr';
    }
    if (route.url.length > 0 && route.url[0].path == 'de') {
      language = 'de';
    }
    if (language != null) {
      localStorage.setItem('block-lang-change', 'true');
      this.i18nService.language = language.endsWith('-CH') ? language : language + '-CH';
      // this.router.onSameUrlNavigation = 'ignore';

      return this.router.navigate(['.'], {
        relativeTo: this.route,
        fragment: route.fragment ? route.fragment : undefined,
        queryParams: { lang: null },
      });
    }
    return of(true);
  }
}
