<div *transloco="let t" class="sectionId_lca sectionAnchor flexBoxOuter">
  <div class="flexBoxInner txt marginRight" style="hyphens: auto">
    <div style="margin-bottom: 26px">
      <h4 data-translate="start.quickNav.build">{{ t("products.start.quickNav.lca") }}</h4>
    </div>

    <div style="margin-top: 33px">
      <h3 data-translate="build.title">{{ t("products.lca.title") }}</h3>
    </div>
    <div style="margin-top: 20px">
      <p class="brightfont" style="display: block; font-weight: bold" [innerHTML]="t('products.lca.text-1-header')"></p>
      <p class="brightfont" style="display: inline">
        {{ t("products.lca.text-1-1") }}
      </p>
      <p class="brightfont" style="display: inline; font-weight: bold">{{ t("products.lca.text-1-2") }}</p>
      <br />
      <p style="display: inline; hyphens: auto" class="brightfont">
        {{ t("products.lca.text-1-3") }}
      </p>
      <div class="newsBox">
        <ul style="list-style-type: none">
          <li class="brightfont">
            {{ t("products.lca.bp-1") }}
          </li>
          <li class="brightfont">
            {{ t("products.lca.bp-2") }}
          </li>
          <li class="brightfont">
            {{ t("products.lca.bp-3") }}
          </li>
          <li class="brightfont">
            {{ t("products.lca.bp-4") }}
          </li>
          <li class="brightfont">
            {{ t("products.lca.bp-5") }}
          </li>
        </ul>
      </div>
    </div>

    <p class="brightfont" style="margin-top: 10px; display: block; font-weight: normal">
      {{ t("products.lca.text-2-header") }}
    </p>
    <p class="brightfont" style="margin-top: 10px; display: block; font-weight: bold">
      {{ t("products.lca.text-3-header") }}
    </p>
    <p class="brightfont" style="margin-bottom: 10px" data-translate="products.text-2">
      {{ t("products.products.text-2") }}
    </p>

    <div style="margin-bottom: 20px">
      <button *ngIf="!isAuthenticated" class="btn btn-elegant-black kw-btn-grid" [routerLink]="'7tage'">
        <span data-translate="use_free_month">{{ t("products.use_free_month") }}</span>
      </button>
      <ng-container *ngIf="isAuthenticated">
        <button *ngIf="hasLcaCosts" class="btn btn-elegant-black kw-btn-grid" (click)="goToForm()">
          <mat-icon class="material-symbols-outlined" style="font-size: 20px; vertical-align: middle">
            play_arrow </mat-icon
          >&nbsp;
          <span>{{ t("products.calc_now") }}</span>
        </button>
        <button *ngIf="!hasLcaCosts" (click)="openModal()" class="btn btn-elegant-black kw-btn-grid">
          <i class="fa fa-credit-card" style="height: 16px; width: 16px"></i>&nbsp;
          <span data-translate="get_license">{{ t("products.get_license") }}</span>
        </button>
      </ng-container>
      <!--<a
        class="btn btn-elegant-black kw-btn-grid"
        ui-sref="accuracy_operating_costs"
        target="_blank"
        data-translate="learn_more"
        [routerLink]="'info-operating-costs'"
      >
        {{ t("products.learn_more") }}
      </a>-->
      <!--<a
        class="btn btn-elegant-black kw-btn-grid"
        href="../../../../pdf/{{ '' | currentLanguage }}/keeValue_AG-Flyer.pdf"
        target="_blank"
      >
        <img src="/assets/images/file-pdf.svg" width="16" class="file-pdf align-icon" />&nbsp;
        <span data-translate="flyer">{{ t("products.flyer") }}</span>
      </a>-->
    </div>
  </div>

  <div class="flexBoxInner img">
    <div class="centerImage centerImgVertically">
      <img src="/assets/images/products/lca.svg" style="align-self: center; max-width: 402px; max-height: 402px" />
      <!--      <img-->
      <!--        src="/assets/images/products/lca_coming_soon_BG.svg"-->
      <!--        style="align-self: center; max-width: 402px; max-height: 402px"-->
      <!--      />-->
    </div>
  </div>
</div>
