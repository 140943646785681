import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BillingModalService } from '@app/core/services/billing/billing/billing-modal/billing-modal.service';
import { WithdrawalProductType } from '@generated/generatedEntities';

@Component({
  selector: 'app-lcc',
  templateUrl: './lcc.component.html',
  styleUrls: ['./lcc.component.scss'],
})
export class LccComponent implements OnInit {
  private router = inject(Router);
  @Input() isAuthenticated!: boolean;
  @Input() hasLccCosts!: boolean;
  @Output() openBillingModal: EventEmitter<WithdrawalProductType> = new EventEmitter();

  ngOnInit(): void {}
  goToLccForm() {
    this.router.navigate(['/lcc-form/input']);
  }

  openModal() {
    this.openBillingModal.emit('LCC_PACKAGE');
  }
}
