import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { VideoDetailsService } from '@app/core/services/video-details.service';
import { WithdrawalProductType } from '@generated/generatedEntities';

@Component({
  selector: 'app-lca',
  templateUrl: './lca.component.html',
  styleUrls: ['./lca.component.scss'],
})
export class LcaComponent implements OnInit {
  private videoDetailService = inject(VideoDetailsService);
  private router = inject(Router);
  @Input() isAuthenticated!: boolean;
  @Input() hasLcaCosts!: boolean;
  @Output() openBillingModal: EventEmitter<WithdrawalProductType> = new EventEmitter();

  ngOnInit(): void {}

  openModal() {
    this.openBillingModal.emit('LCA');
  }

  // goToInfo() {
  //   this.router.navigate([]).then((result) => {
  //     window.open('/info', '_blank');
  //   });
  // }

  goToForm() {
    this.router.navigate(['/lca-form/input']);
  }
}
