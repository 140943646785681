<div id="sectionId_team"></div>
<div
  *transloco="let t"
  class="sectionId_team sectionAnchor flexBoxOuter"
  style="padding: 50px 20px; background: #e8e8e8"
>
  <div class="flexBoxInnerWider txt marginRight" style="hyphens: auto">
    <div style="margin-bottom: 26px">
      <h4 data-translate="start.quickNav.team">{{ t("products.start.quickNav.team") }}</h4>
    </div>

    <div class="brightfont clearFloat"></div>
    <div class="kw-team-row">
      <div class="kw-team-card">
        <div class="cImage kw-team-img-container">
          <img style="object-position: center" class="kv-team-img" src="/assets/images/team/SC.jpg" alt="SC" />
        </div>
        <div class="kw-team-text">
          <label>Stefan Cadosch</label>
          <span>{{ t("products.team.architect") }}, CEO</span>
        </div>
      </div>
      <div class="kw-team-card">
        <div class="cImage kw-team-img-container">
          <img style="object-position: center" class="kv-team-img" src="/assets/images/team/MI.jpg" alt="MI" />
        </div>
        <div class="kw-team-text">
          <label>Michael Imbach</label>
          <span>{{ t("products.team.lead_dev") }}, CTO</span>
        </div>
      </div>
      <div class="kw-team-card">
        <div class="cImage kw-team-img-container">
          <img style="object-position: center" class="kv-team-img" src="/assets/images/team/MB.jpg" alt="MB" />
        </div>
        <div class="kw-team-text">
          <label>Mischa Badertscher</label>
          <span>{{ t("products.team.architect") }}, CPO</span>
        </div>
      </div>
      <div class="kw-team-card">
        <div class="cImage kw-team-img-container">
          <img style="object-position: center" class="kv-team-img" src="/assets/images/team/JC.jpg" alt="JC" />
        </div>
        <div class="kw-team-text">
          <label>Juan Crevillén</label>
          <span>{{ t("products.team.architect") }}, {{ t("products.team.dm") }}</span>
        </div>
      </div>
    </div>
    <div class="kw-team-row">
      <div class="kw-team-card">
        <div class="cImage kw-team-img-container">
          <img style="object-position: center" class="kv-team-img" src="/assets/images/team/LF.jpg" alt="LF" />
        </div>
        <div class="kw-team-text">
          <label>Lukas Fonti</label>
          <span>{{ t("products.team.fsd") }}</span>
        </div>
      </div>
      <div class="kw-team-card">
        <div class="cImage kw-team-img-container">
          <img style="object-position: center" class="kv-team-img" src="/assets/images/team/DH.jpg" alt="DH" />
        </div>
        <div class="kw-team-text">
          <label>Daniel Hunziker</label>
          <span>{{ t("products.team.bd") }}</span>
        </div>
      </div>
      <div class="kw-team-card">
        <div class="cImage kw-team-img-container">
          <img style="object-position: center" class="kv-team-img" src="/assets/images/team/BT.jpg" alt="BT" />
        </div>
        <div class="kw-team-text">
          <label>Beat Theiler</label>
          <span>{{ t("products.team.sm") }}</span>
        </div>
      </div>
      <div class="kw-team-card">
        <div class="cImage kw-team-img-container">
          <img style="object-position: center" class="kv-team-img" src="/assets/images/team/AM.jpg" alt="BT" />
        </div>
        <div class="kw-team-text">
          <label>Aniket Mandal</label>
          <span>{{ t("products.team.fsd") }}</span>
        </div>
      </div>
    </div>
    <div class="kw-team-row">
      <div class="kw-team-card">
        <div class="cImage kw-team-img-container">
          <img style="object-position: center" class="kv-team-img" src="/assets/images/team/VK.jpg" alt="BT" />
        </div>
        <div class="kw-team-text">
          <label>Vitali Kazlou</label>
          <span>{{ t("products.team.fsd") }}</span>
        </div>
      </div>
      <div class="kw-team-card">
        <div class="cImage kw-team-img-container">
          <img style="object-position: center" class="kv-team-img" src="/assets/images/team/AB.jpg" alt="BT" />
        </div>
        <div class="kw-team-text">
          <label>Alex Bigel</label>
          <span>{{ t("products.team.fsd") }}</span>
        </div>
      </div>
      <div class="kw-team-card">
        <div class="cImage kw-team-img-container">
          <img style="object-position: center" class="kv-team-img" src="/assets/images/team/PD.jpg" alt="BT" />
        </div>
        <div class="kw-team-text">
          <label>Peter Deviarylin</label>
          <span>{{ t("products.team.fsd") }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
