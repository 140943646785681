<div *transloco="let t" class="sectionId_pf sectionAnchor flexBoxOuter">
  <div class="flexBoxInner txt marginRight" style="hyphens: auto">
    <div style="margin-bottom: 26px">
      <h4>{{ t("products.start.quickNav.pf") }}</h4>
    </div>

    <div style="margin-top: 33px">
      <h3>{{ t("products.pf.title") }}</h3>
    </div>

    <div style="margin-top: 20px">
      <p
        class="brightfont"
        style="display: block; font-weight: bold; margin-top: 20px"
        [innerHTML]="t('products.pf.text-1-header')"
      ></p>
      <p class="brightfont" style="margin-top: 10px; display: inline">
        {{ t("products.pf.text-1-1") }}
      </p>

      <br />

      <div class="newsBox">
        <ul style="list-style-type: none">
          <li class="brightfont">
            {{ t("products.pf.bp-1") }}
          </li>

          <p class="brightfont" style="display: inline; hyphens: auto">{{ t("products.pf.text-1-2") }}</p>

          <li class="brightfont">
            {{ t("products.pf.bp-2") }}
          </li>
        </ul>
      </div>
    </div>

    <br />

    <p style="display: inline; hyphens: auto" class="brightfont" [innerHTML]="t('products.pf.text-1-3')"></p>

    <br />

    <p style="display: inline; hyphens: auto" class="brightfont">
      {{ t("products.pf.text-1-4") }}
    </p>

    <p class="brightfont" style="margin-top: 10px; display: block; font-weight: bold">
      {{ t("products.pf.text-2-header") }}
    </p>

    <div style="margin-bottom: 20px">
      <button *ngIf="!isAuthenticated" class="btn btn-elegant-black kw-btn-grid" [routerLink]="'7tage'">
        <span>{{ t("products.use_free_month") }}</span>
      </button>
      <ng-container *ngIf="isAuthenticated">
        <button *ngIf="hasModule" class="btn btn-elegant-black kw-btn-grid" (click)="goToForm()">
          <mat-icon class="material-symbols-outlined" style="font-size: 20px; vertical-align: middle">
            play_arrow </mat-icon
          >&nbsp;
          <span>{{ t("products.calc_now") }}</span>
        </button>
        <!--        <button *ngIf="!hasModule" (click)="openModal()" class="btn btn-elegant-black kw-btn-grid">-->
        <!--          <i class="fa fa-credit-card" style="height: 16px; width: 16px"></i>&nbsp;-->
        <!---->
        <!--        </button>-->
      </ng-container>
    </div>
  </div>

  <div class="flexBoxInner img">
    <div class="centerImage centerImgVertically">
      <img
        src="/assets/images/calculation/KatasterAll1.png"
        style="align-self: center; max-width: 388px; max-height: 388px"
      />
    </div>
  </div>
</div>
