<div>
  <mat-card class="p-0 kv-mat-card" *transloco="let t" id="sectionId_prices">
    <div #sectionId_prices class="pageId_Register flexBoxOuter" [ngClass]="backGroundColor">
      <div class="flexBoxInner img">
        <div class="centerImage center-img-box">
          <img style="align-self: center" src="/assets/images/products/einmalige_berechnung_BG.svg" />
        </div>
      </div>
      <div class="flexBoxInner txt marginRight align-inner">
        <div class="">
          <div>
            <mat-card-title class="keevalue-title">
              {{ t("prices.price.title") }}
            </mat-card-title>
          </div>

          <div class="baukosten-box">
            <p class="brightfont" [innerHtml]="t('prices.price.text-1-0')"></p>
            <p class="brightfont mt-3" [innerHtml]="t('prices.price.text-1-2')"></p>
          </div>
          <div class="mt-10">
            <table mat-table [dataSource]="dataSource" class="price-table">
              <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              <ng-container matColumnDef="empty">
                <th mat-header-cell *matHeaderCellDef class="fontbold"></th>
                <td mat-cell *matCellDef="let element"></td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <!-- Position Column -->
              <ng-container matColumnDef="company size">
                <th mat-header-cell *matHeaderCellDef class="fontbold">{{ t("prices.price.table.header-1") }}</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.name !== 'XL'">
                    <span *ngIf="element.numMinEmployees <= 0"
                      >&le; {{ element.numMaxEmployees }} {{ t("prices.price.table.employees") }}</span
                    >
                    <!--                    <span *ngIf="element.numMaxEmployees <= 0">-->
                    <!--                      {{ element.numMinEmployees}} bis {{ 100}}-->
                    <!--                      {{ t('prices.price.table.employees') }}</span-->
                    <!--                    >-->
                    <span *ngIf="element.numMinEmployees > 0 && element.numMaxEmployees > 0">
                      {{ element.numMinEmployees }} {{ t("prices.price.table.upto") }} {{ element.numMaxEmployees }}
                      {{ t("prices.price.table.employees") }}
                    </span>
                  </ng-container>
                  <span *ngIf="element.name == 'XL'">
                    &ge; {{ element.numMinEmployees }} {{ t("prices.price.table.employees") }}
                  </span>
                </td>
                <td mat-footer-cell *matFooterCellDef>{{ t("prices.price.table.additional-account") }}</td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="monthly costs">
                <th mat-header-cell *matHeaderCellDef class="fontbold">
                  {{ t("prices.price.table.header-2a") }}
                </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="element.name != 'XL'">
                    {{ (12 * element.monthlyBaseFee) / 100 | currency: "CHF " : "symbol" : "1.2-2" }}
                  </div>
                  <div *ngIf="element.name == 'XL'" data-translate="">
                    {{ t("prices.price.table.on-request") }}
                  </div>
                </td>
                <td mat-footer-cell *matFooterCellDef>{{ t("prices.price.table.20-percent") }}</td>
              </ng-container>
              <!--              package costs-->
              <ng-container matColumnDef="package costs">
                <th mat-header-cell *matHeaderCellDef class="fontbold">
                  {{ t("prices.price.table.header-3a") }}
                </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="element.name != 'XL'">
                    {{
                      (12 *
                        (element.monthlyBaseFee +
                          element.monthlyBaseFee * discountModule2 +
                          element.monthlyBaseFee * discountModule3 +
                          element.monthlyBaseFee * discountModule3)) /
                        100 | currency: "CHF " : "symbol" : "1.2-2"
                    }}
                  </div>
                  <div *ngIf="element.name == 'XL'" data-translate="">
                    {{ t("prices.price.table.on-request") }}
                  </div>
                </td>
                <td mat-footer-cell *matFooterCellDef>{{ t("prices.price.table.20-percent") }}</td>
              </ng-container>

              <ng-container matColumnDef="empty2">
                <th mat-header-cell *matHeaderCellDef class="fontbold"></th>
                <td mat-cell *matCellDef="let element"></td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
          </div>
          <!-- <div class="centeredBoxText alert alert-info mb-3 mt-3">
            <ul class="news-box-list">
              <li>{{ t('prices.price.table.box.discount') }}</li>
              <li>
                {{ t('prices.price.table.box.discount-sia-1') }} 10 {{ t('prices.price.table.box.discount-sia-2') }} 20
                {{ t('prices.price.table.box.discount-sia-3') }}
              </li>
            </ul>
          </div> -->
          <div class="newsBox alert alert-kennwerte-info centeredBoxText" style="margin-top: 20px; margin-bottom: 20px">
            <ul style="list-style-type: none; text-align: left; margin-top: 0; margin-left: 1em">
              <li style="list-style-position: outside">
                {{ t("prices.price.table.box.discount") }}
              </li>
              <li style="list-style-position: outside">
                {{ t("prices.price.table.box.discount-sia-1") }}
                {{ discountInPercentIfSiaIndividual | number: "1.0-0" }} {{ t("prices.price.table.box.discount-sia-2")
                }}{{ discountInPercentIfSiaCorporate | number: "1.0-0" }}
                {{ t("prices.price.table.box.discount-sia-3") }}
              </li>
            </ul>
          </div>

          <div class="brightfont">
            <ul class="list-unstyled">
              <li data-translate="price.table.addition-1">
                {{ t("prices.price.table.addition-1") }} {{ t("prices.price.table.addition-2") }}
              </li>
              <!--              <li data-translate="price.table.addition-3">{{ t('prices.price.table.addition-3') }}</li>-->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <ng-container *ngIf="!isAuthenticated">
    <ng-container *ngIf="showBuildingCosts">
      <app-register-form></app-register-form>
    </ng-container>
  </ng-container>
</div>
