import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { WithdrawalProductType } from '@generated/generatedEntities';

@Component({
  selector: 'app-rebuild',
  templateUrl: './rebuild.component.html',
  styleUrls: ['./rebuild.component.scss'],
})
export class RebuildComponent implements OnInit {
  private router = inject(Router);
  @Input() isAuthenticated!: boolean;
  @Input() hasRebuildCosts!: boolean;
  @Output() openBillingModal: EventEmitter<WithdrawalProductType> = new EventEmitter();

  ngOnInit(): void {}

  openBillingModalForRebuild() {
    this.openBillingModal.emit('REBUILD_COSTS');
  }

  goToRebuildForm() {
    this.router.navigate(['/rebuild-form/input']);
  }
}
