<!-- <div id='sectionId_webinar'></div> -->

<div
  *transloco="let t"
  #sectionId_webinar
  id="sectionId_webinar"
  class="rowBackgroundRed sectionId_webinar sectionAnchor flexBoxOuter dont-hide-circle"
>
  <div class="flexBoxInner txt marginRight" style="hyphens: auto">
    <div class="rowBackgroundRed" style="margin-bottom: 0px">
      <div style="display: inline">
        <h3
          class="kennwertered"
          style="color: white; display: inline-block; margin-right: 0px; margin-top: 0; margin-bottom: 0px"
          data-translate="webinar.header"
        >
          {{ t("products.webinar.header") }}
        </h3>
      </div>
      <div class="newsBox">
        <ul style="list-style-type: none">
          <li style="color: white; padding-top: 10px; width: 100%">
            <span class="newsBoxButtonText" data-translate="webinar.comingsoon">{{
              t("products.webinar.comingsoon")
            }}</span>
          </li>
          <!--<li style="color: white; padding-top: 10px; width: 100%">
            <div style="width: 69%; display: inline-flex">
              <span>{{ t("products.webinar.building-costs", { date: "07.02.2024" }) }}</span>
            </div>
            <a
              class="btn btn-elegant-white newsBoxButton"
              rel="noopener"
              target="_blank"
              href="https://www.sia.ch/de/dienstleistungen/sia-inform/detail/event/9232/nc/1/"
              style="vertical-align: baseline"
            >
              <span class="newsBoxButtonText">{{ t("products.webinar.more-info") }}</span>
              <span class="newsBoxButtonText2">{{ t("products.webinar.info") }}</span>
            </a>
          </li>-->

          <!--<li style="color: white; padding-top: 10px; width: 100%">
            <div style="width: 69%; display: inline-flex">
              <span>{{ t("products.webinar.building-costs", { date: "25.09.24" }) }}</span>
            </div>
            <a
              class="btn btn-elegant-white newsBoxButton"
              rel="noopener"
              target="_blank"
              href="https://forms.gle/J1xega4PBJ6H1X4H6"
              style="vertical-align: baseline"
            >
              <span class="newsBoxButtonText">{{ t("products.webinar.more-info") }}</span>
              <span class="newsBoxButtonText2">{{ t("products.webinar.info") }}</span>
            </a>
          </li>

          <li style="color: white; padding-top: 10px; width: 100%">
            <div style="width: 69%; display: inline-flex">
              <span>{{ t("products.webinar.rebuild-costs", { date: "11.10.24" }) }} </span>
            </div>
            <a
              class="btn btn-elegant-white newsBoxButton"
              rel="noopener"
              target="_blank"
              href="https://forms.gle/J1xega4PBJ6H1X4H6"
              style="vertical-align: baseline"
            >
              <span class="newsBoxButtonText">{{ t("products.webinar.more-info") }}</span>
              <span class="newsBoxButtonText2">{{ t("products.webinar.info") }}</span>
            </a>
          </li>-->

          <!-- <li style="color: white; padding-top: 10px; width: 100%">
            <div style="width: 69%; display: inline-flex">
              <span>{{ t("products.webinar.building-costs", { date: "21.05.2024" }) }}</span>
            </div>
            <a
              class="btn btn-elegant-white newsBoxButton"
              rel="noopener"
              target="_blank"
              href="https://digi-neubaukosten.events.sia.ch/"
              style="vertical-align: baseline"
            >
              <span class="newsBoxButtonText">{{ t("products.webinar.more-info") }}</span>
              <span class="newsBoxButtonText2">{{ t("products.webinar.info") }}</span>
            </a>
          </li>-->

          <!-- <li style="color: white; padding-top: 10px; width: 100%">
                      <div style="width: 69%; display: inline-flex">
                        <span>{{ t("products.webinar.rebuild-costs", { date: "20.05.2024" }) }}</span>
                      </div>
                      <a
                        class="btn btn-elegant-white newsBoxButton"
                        rel="noopener"
                        target="_blank"
                        href="https://digitalisierte-umbaukostenschaetzungen.events.sia.ch/"
                        style="vertical-align: baseline"
                      >
                        <span class="newsBoxButtonText">{{ t("products.webinar.more-info") }}</span>
                        <span class="newsBoxButtonText2">{{ t("products.webinar.info") }}</span>
                      </a>
                    </li>-->

          <!--                     <li style="color: white; padding-top: 10px; width: 100%">-->
          <!--                      <div style="width: 69%; display: inline-flex">-->
          <!--                        <span>{{ t("products.webinar.building-costs", { date: "29.11.2023" }) }}</span>-->
          <!--                      </div>-->
          <!--                      <a-->
          <!--                        class="btn btn-elegant-white newsBoxButton"-->
          <!--                        rel="noopener"-->
          <!--                        target="_blank"-->
          <!--                        href="https://www.sia.ch/de/dienstleistungen/sia-inform/detail/event/8184/nc/1/"-->
          <!--                        style="vertical-align: baseline"-->
          <!--                      >-->
          <!--                        <span class="newsBoxButtonText">{{ t("products.webinar.more-info") }}</span>-->
          <!--                        <span class="newsBoxButtonText2">{{ t("products.webinar.info") }}</span>-->
          <!--                      </a>-->
          <!--                    </li>-->
          <!--          <li style="color: white; padding-top: 10px; width: 100%">-->
          <!--            <div style="width: 69%; display: inline-flex">-->
          <!--              <span>{{ t("products.webinar.building-costs", { date: "13.12.2023" }) }}</span>-->
          <!--            </div>-->
          <!--            <a-->
          <!--              class="btn btn-elegant-white newsBoxButton"-->
          <!--              rel="noopener"-->
          <!--              target="_blank"-->
          <!--              href="https://www.sia.ch/de/dienstleistungen/sia-inform/detail/event/8785/nc/1/"-->
          <!--              style="vertical-align: baseline"-->
          <!--            >-->
          <!--              <span class="newsBoxButtonText">{{ t("products.webinar.more-info") }}</span>-->
          <!--              <span class="newsBoxButtonText2">{{ t("products.webinar.info") }}</span>-->
          <!--            </a>-->
          <!--          </li>-->

          <!--      swissbau entry    -->
          <!--                    <li style="color: white; padding-top: 10px; width: 100%">-->
          <!--            <div style="width: 69%; display: inline-flex">-->
          <!--              <span-->
          <!--              >{{ t("products.show.swissbau") }} {{ t("products.show.swissbau-1") }}-->
          <!--                {{ t("products.show.swissbau-2") }} {{ t("products.show.swissbau-3") }}</span-->
          <!--              >-->
          <!--            </div>-->
          <!--            <a-->
          <!--              class="btn btn-elegant-white newsBoxButton"-->
          <!--              rel="noopener"-->
          <!--              target="_blank"-->
          <!--              href='https://www.swissbau.ch/de/e/lebenszykluskosten-praezision-durch-kuenstliche-intelligenz.39492'-->
          <!--              style="vertical-align: baseline"-->
          <!--            >-->
          <!--              <span class="newsBoxButtonText">{{ t("products.webinar.more-info") }}</span>-->
          <!--              <span class="newsBoxButtonText2">{{ t("products.webinar.info") }}</span>-->
          <!--            </a>-->
          <!--          </li>-->
        </ul>
      </div>
    </div>
    <br />
    <br />
    <div class="rowBackgroundRed" style="margin-bottom: 10px">
      <div style="display: inline">
        <h3
          class="kennwertered"
          style="color: white; display: inline-block; margin-right: 0px; margin-top: 0; margin-bottom: 0px"
        >
          {{ t("products.news.header") }}
        </h3>
      </div>
      <div class="newsBox">
        <ul style="list-style-type: none">
          <li style="color: white; padding-top: 10px; width: 100%">
            <div style="width: 69%; display: inline-flex">
              <span>{{ t("products.news.news-3") }}</span>
            </div>
            <a
              class="btn btn-elegant-white newsBoxButton"
              rel="noopener"
              target="_blank"
              href="pdf/{{ '' | currentLanguage }}/keeValue_LCA.pdf"
              style="vertical-align: baseline"
            >
              <span class="newsBoxButtonText">{{ t("products.news.news-3-button") }}</span>
              <span class="newsBoxButtonText2">{{ t("products.news.news-3-button") }}</span>
            </a>
          </li>
          <li style="color: white; padding-top: 10px; width: 100%">
            <div style="width: 69%; display: inline-flex">
              <span>{{ t("products.news.news-2") }}</span>
            </div>
            <a
              class="btn btn-elegant-white newsBoxButton"
              rel="noopener"
              target="_blank"
              href="https://www.swissbau.ch/de/c/lebenszykluskosten-praezision-durch-kuenstliche-intelligenz.43791"
              style="vertical-align: baseline"
            >
              <span class="newsBoxButtonText">{{ t("products.news.news-2-button") }}</span>
              <span class="newsBoxButtonText2">{{ t("products.news.news-2-button") }}</span>
            </a>
          </li>
          <li style="color: white; padding-top: 10px; width: 100%">
            <div style="width: 69%; display: inline-flex">
              <span>{{ t("products.news.news-1") }}</span>
            </div>
            <a
              class="btn btn-elegant-white newsBoxButton"
              rel="noopener"
              target="_blank"
              href="pdf/de/keeValue_nzz.pdf"
              style="vertical-align: baseline"
            >
              <span class="newsBoxButtonText">{{ t("products.news.news-1-button") }}</span>
              <span class="newsBoxButtonText2">{{ t("products.news.news-1-button") }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--    <div class="flexBoxInner img">-->
  <!--        <div class="centerImage centerImgVertically" style="position: relative; text-align: center;">-->
  <!--            <a ng-cloak class="swissbau" href="https://www.digitalrealestate.ch/fr/dres-romandie-2022/#1635429797290-32b0b83d-2eb3" target="_blank"-->
  <!--               rel="noopener">-->
  <!--                <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">-->
  <!--                    <style>circle {-->
  <!--                        fill: white;-->
  <!--                        fill-opacity: 0.1;-->
  <!--                        stroke: white;-->
  <!--                        stroke-width: 2px;-->
  <!--                    }-->
  <!--                    </style>-->
  <!--                    <circle class="white-hover-bg" cx="250" cy="250.4" r="241.6"/>-->
  <!--                </svg>-->
  <!--                <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">-->
  <!--                    <span class="swissbau1" data-translate="show.swissbau">Wir sind an </span> <span class="swissbau1-1"-->
  <!--                                                                                                     data-translate="show.swissbau-1">der Swissbau</span><br/><span-->
  <!--                    class="swissbau2"-->
  <!--                    data-translate="show.swissbau-2">3. - 6. Mai</span><br/><span data-translate="show.swissbau-3"-->
  <!--                                                                                       class="swissbau3">Stand L82 InnovationLab</span>-->
  <!--            </span>-->
  <!--            </a>-->
  <!--        </div>-->
  <!--    </div>-->

  <div class="flexBoxInner img">
    <!--<div class="centerImage centerImgVertically" style="position: relative; text-align: center">
      <a
        ng-cloak
        class="swissbau"
        href="https://www.digitalrealestate.ch/digital-real-estate-summit/"
        target="_blank"
        rel="noopener"
      >
        <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <style>
            circle {
              fill: white;
              fill-opacity: 0.1;
              stroke: white;
              stroke-width: 2px;
            }
          </style>
          <circle class="white-hover-bg" cx="250" cy="250.4" r="241.6" />
        </svg>
        <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
          <span class="swissbau1">{{ t('products.show.drs') }}</span
          ><br /><span class="swissbau1-1">{{ t('products.show.drs-1') }}</span
          ><br /><span class="swissbau2">{{ t('products.show.drs-2') }}</span> <br /><span class="swissbau3">{{
            t('products.show.drs-3')
          }}</span>
        </span>
      </a>
    </div>-->

    <!--        we are now on facebook and linkedIn -->
    <!--         <div-->
    <!--            class="centerImage centerImgVertically"-->
    <!--            style="position: relative; text-align: center;"-->
    <!--        >-->
    <!--            <span-->
    <!--                ng-cloak-->
    <!--                class="social"-->
    <!--            >-->
    <!--                <svg-->
    <!--                    id="Ebene_1"-->
    <!--                    xmlns="http://www.w3.org/2000/svg"-->
    <!--                    viewBox="0 0 500 500"-->
    <!--                >-->
    <!--                    <style>-->
    <!--                        circle {-->
    <!--                            fill: white;-->
    <!--                            fill-opacity: 0.1;-->
    <!--                            stroke: white;-->
    <!--                            stroke-width: 2px;-->
    <!--                        }-->
    <!--                    </style>-->
    <!--                    <circle-->
    <!--                        class="white-hover-bg"-->
    <!--                        cx="250"-->
    <!--                        cy="250.4"-->
    <!--                        r="241.6"-->
    <!--                    />-->
    <!--                </svg>-->

    <!--                <span-->
    <!--                    style="-->
    <!--                        position: absolute;-->
    <!--                        top: 45%;-->
    <!--                        left: 50%;-->
    <!--                        transform: translate(-50%, -50%);-->
    <!--                    "-->
    <!--                >-->
    <!--                    <span class="social1" data-translate="show.facebook"-->
    <!--                        >Wir sind auf</span-->
    <!--                    ><br /><a href="https://www.facebook.com/kennwerteadmin/" target="_blank" rel="noopener"  class="social-1-1" data-translate="show.facebook-1">Facebook</a>-->

    <!--                    <br /><span class="social2" data-translate="show.facebook-2"-->
    <!--                        >und</span-->
    <!--                    >-->
    <!--                    <br /><a href="https://ch.linkedin.com/company/kennwerte-ag" target="_blank" rel="noopener" class="social3"  data-translate="show.facebook-3"></a>-->

    <!--                </span>-->
    <!--            </span>-->
    <!--        </div>-->

    <!--   einsparung durch CO2     -->
    <div class="centerImage centerImgVertically" style="position: relative; text-align: center">
      <a ng-cloak class="swissbau" href="pdf/{{ '' | currentLanguage }}/keeValue_CO2.pdf" target="_blank">
        <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <style>
            circle {
              fill: white;
              fill-opacity: 0.1;
              stroke: white;
              stroke-width: 2px;
            }
          </style>
          <circle class="white-hover-bg" cx="250" cy="250.4" r="241.6" />
        </svg>
        <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
          <span class="co2-1">CO<sub>2</sub></span
          ><br /><span class="swissbau1-1" data-translate="show.co2-2">{{ t("products.show.co2-2") }}</span
          ><br /><span class="co2-3" data-translate="show.co2-3">{{ t("products.show.co2-3") }}</span>
        </span>
      </a>
    </div>
  </div>
</div>
