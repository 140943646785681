import { Component, OnInit } from '@angular/core';
import { WithdrawalProductType } from '@generated/generatedEntities';
import { WebinarContainer } from '@generated/extended';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';

@Component({
  selector: 'app-webinar-de',
  templateUrl: './webinar-de.component.html',
  styleUrls: ['./webinar-de.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: ['products'],
    },
  ],
})
export class WebinarDeComponent implements OnInit {
  webinars: WebinarContainer[] = [
    {
      url: 'https://www.sia.ch/de/dienstleistungen/sia-inform/detail/event/8183/nc/1/',
      type: 'REBUILD_COSTS',
      date: '2023-04-19T14:00:00.000Z',
    },
    {
      url: 'https://www.sia.ch/de/dienstleistungen/sia-inform/detail/event/8192/nc/1/',
      type: 'BUILDING_COSTS_AND_DATES',
      date: '2023-05-03T14:00:00.000Z',
    },
    {
      url: 'https://www.sia.ch/de/dienstleistungen/sia-inform/detail/event/8184/nc/1/',
      type: 'REBUILD_COSTS',
      date: '2023-05-24T14:00:00.000Z',
    },
    {
      url: 'https://www.sia.ch/de/dienstleistungen/sia-inform/detail/event/8193/nc/1/',
      type: 'BUILDING_COSTS_AND_DATES',
      date: '2023-06-14T14:00:00.000Z',
    },
  ];

  ngOnInit(): void {}
}
