<div *transloco="let t" class="sectionId_baukosten sectionAnchor flexBoxOuter">
  <div class="flexBoxInner txt marginRight" style="hyphens: auto">
    <div style="margin-bottom: 26px">
      <h4 data-translate="start.quickNav.build">{{ t("products.start.quickNav.build") }}</h4>
    </div>

    <div style="margin-top: 33px">
      <h3 data-translate="build.title">{{ t("products.build.title") }}</h3>
    </div>
    <div style="margin-top: 20px">
      <p
        class="brightfont"
        style="display: block; font-weight: bold"
        data-translate="build.text-1-header"
        [innerHTML]="t('products.build.text-1-header')"
      ></p>
      <p class="brightfont" style="display: inline" data-translate="build.text-1-1">
        {{ t("products.build.text-1-1") }}
      </p>
      <u
        ><a
          class="brightfont info-link"
          ui-sref="accuracy"
          target="_blank"
          translate="build.text-1-2"
          (click)="goToInfo()"
          >{{ t("products.build.text-1-2") }}</a
        ></u
      >

      <p
        style="display: inline; hyphens: auto"
        class="brightfont"
        data-translate="build.text-1-3"
        [innerHTML]="t('products.build.text-1-3')"
      ></p>
    </div>

    <p
      class="brightfont"
      style="margin-top: 10px; display: block; font-weight: bold"
      data-translate="build.text-2-header"
    >
      {{ t("products.build.text-2-header") }}
    </p>

    <p class="brightfont" style="margin-bottom: 10px" data-translate="products.text-2">
      {{ t("products.products.text-2") }}
    </p>

    <div style="margin-bottom: 20px">
      <button
        *ngIf="!IsAuthenticated"
        ui-sref="register"
        class="btn btn-elegant-black kw-btn-grid"
        id="buildingCostsAndDates"
        [routerLink]="'7tage'"
      >
        <span data-translate="use_free_month">{{ t("products.use_free_month") }}</span>
      </button>
      <ng-container *ngIf="IsAuthenticated">
        <button
          *ngIf="hasBuildingCosts"
          ui-sref="process-form.input({withdrawalProductType: 'building_costs_and_dates'})"
          data-ui-sref-opts="{reload: 'operational-costs'}"
          class="btn btn-elegant-black kw-btn-grid"
          (click)="goToProcessForm()"
        >
          <!--          <span>-->
          <!--            <mat-icon class="material-symbols-outlined" style="width: 16px; font-size: 20px; vertical-align: middle">-->
          <!--              play_arrow </mat-icon-->
          <!--            >{{ t('products.calc_now') }}</span-->
          <!--          >-->
          <mat-icon class="material-symbols-outlined" style="font-size: 20px; vertical-align: middle">
            play_arrow </mat-icon
          >&nbsp;
          <span>{{ t("products.calc_now") }}</span>
        </button>
        <button
          *ngIf="!hasBuildingCosts"
          (click)="openBillingModalForBuild()"
          class="btn btn-elegant-black kw-btn-grid"
        >
          <i class="fa fa-credit-card" style="height: 16px; width: 16px"></i>&nbsp;
          <span data-translate="get_license">{{ t("products.get_license") }}</span>
        </button>
      </ng-container>
      <a class="btn btn-elegant-black kw-btn-grid" ui-sref="accuracy" target="_blank" (click)="goToInfo()"
        >{{ t("products.learn_more") }}
      </a>

      <button class="btn btn-elegant-black kw-btn-grid" ui-sref="products.video" (click)="openVideoDialog()">
        <img src="/assets/images/youtube2.svg" class="align-icon" width="18" />&nbsp;
        <span data-translate="build.name">{{ t("products.build.name") }}</span>
      </button>

      <a
        class="btn btn-elegant-black kw-btn-grid"
        href="../../../../pdf/{{ '' | currentLanguage }}/keeValue_AG-Flyer.pdf"
        target="_blank"
      >
        <img src="/assets/images/file-pdf.svg" class="file-pdf align-icon" width="16" />&nbsp;
        <span data-translate="flyer">{{ t("products.flyer") }}</span>
      </a>
    </div>
    <ng-container ui-view="webinar"></ng-container>
  </div>

  <div class="flexBoxInner img">
    <div class="centerImage centerImgVertically">
      <img src="/assets/images/products/baukosten_termine_BG.svg" style="align-self: center" />
    </div>
  </div>
</div>
