<div id="sectionId_intro"></div>

<div
  *transloco="let t"
  class="sectionId_intro sectionAnchor flexBoxOuter section-blue-color"
  style="padding: 50px 20px"
>
  <div class="flexBoxInner txt marginRight" style="hyphens: auto">
    <div style="margin-bottom: 26px">
      <h4 data-translate="start.quickNav.intro">{{ t("products.start.quickNav.intro") }}</h4>
    </div>

    <div style="margin-top: 33px">
      <h3 data-translate="intro.title">{{ t("products.intro.title") }}</h3>
      <h3>keeValue.ch</h3>
    </div>
    <div style="margin-top: 20px">
      <!--            <p class="brightfont" style="display:block; font-weight: bold;" data-translate="intro.text-1-header"></p>-->
      <p
        class="brightfont"
        style="display: inline"
        data-translate="intro.text-1-1"
        [innerHTML]="t('products.intro.text-1-1')"
      ></p>
      <p
        style="display: inline; hyphens: auto"
        class="brightfont"
        data-translate="intro.text-1-2"
        [innerHTML]="t('products.intro.text-1-2')"
      ></p>
      <br />
      <span
        class="brightfont"
        style="display: inline; font-weight: bold"
        data-translate="intro.text-2-header"
        [innerHTML]="t('products.intro.text-2-header')"
      ></span>

      <span
        style="display: inline; hyphens: auto"
        class="brightfont"
        data-translate="intro.text-2-1"
        [innerHTML]="t('products.intro.text-2-1')"
      ></span>
    </div>

    <p
      class="brightfont"
      style="margin-top: 10px; display: block; font-weight: bold"
      data-translate="intro.text-3-header"
      [innerHTML]="t('products.intro.text-3-header')"
    >
      {{ t("products.intro.text-3-header") }}
    </p>

    <div style="margin-bottom: 20px">
      <button
        *ngIf="!isAuthenticated"
        ui-sref="register"
        class="btn btn-elegant-black kw-btn-grid"
        id="buildingCostsAndDates"
        [routerLink]="'7tage'"
      >
        <span data-translate="use_free_month">{{ t("products.use_free_month") }}</span>
      </button>
      <!--                    <ng-container ng-if="vm.isAuthenticated"-->
      <!--                    >&nbsp;-->
      <!--                        <button ng-if="vm.hasBuildingCosts"-->
      <!--                                ui-sref="process-form.input({withdrawalProductType: 'building_costs_and_dates'})"-->
      <!--                                data-ui-sref-opts="{reload: 'operational-costs'}"-->
      <!--                                class="btn btn-elegant-black kw-btn-grid">-->
      <!--                            <i class="fa fa-pencil" style="height: 16px; width: 16px"></i>&nbsp;-->
      <!--                            <span data-translate="calc_now">Jetzt berechnen</span>-->
      <!--                        </button>-->
      <!--                        <button ng-if="!vm.hasBuildingCosts"-->
      <!--                                ui-sref="billing"-->
      <!--                                class="btn btn-elegant-black kw-btn-grid">-->
      <!--                            <i class="fa fa-pencil" style="height: 16px; width: 16px"></i>&nbsp;-->
      <!--                            <span data-translate="get_license">Lizenz erwerben</span>-->
      <!--                        </button>-->
      <!--                    </ng-container>-->

      <a
        class="btn btn-elegant-black kw-btn-grid"
        href="../../../../pdf/{{ '' | currentLanguage }}/keeValue_AG-Flyer.pdf"
        target="_blank"
      >
        <img src="/assets/images/file-pdf.svg" width="16" class="file-pdf align-icon" />&nbsp;
        <span data-translate="flyer">{{ t("products.flyer") }}</span>
      </a>
    </div>
  </div>

  <div class="flexBoxInner img">
    <div class="centerImage centerImgVertically" style="position: relative; text-align: center">
      <!--            <div style="align-self: center; background:#D1D9E6;border-radius: 50%;">-->
      <img src="/assets/images/products/placeholder_BG.svg" style="align-self: center" />
      <span>
        <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
          <!-- <kw-counter></kw-counter> -->
          <div class="cirlce-text">
            <h1 class="counter-text into-h1">
              <app-animated-digit [digit]="countTo" [duration]="2000"></app-animated-digit>
            </h1>
            <p *ngIf="showCounter" class="counter-text-color">{{ t("products.intro.counter.unit") }}</p>
          </div>
          <!-- <app-animated-digit [digit]="9266" [duration]="5000"></app-animated-digit> -->
        </span>
      </span>
    </div>
  </div>
</div>
