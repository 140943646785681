<ng-container *ngIf="hasInsuranceCosts && isAuthenticated">
  <div id="sectionId_versicherungswerte"></div>
  <div
    *transloco="let t"
    class="sectionId_versicherungswerte sectionAnchor flexBoxOuter"
    style="padding: 50px 20px; background: #e5f0f9"
  >
    <div class="flexBoxInner txt marginRight" style="hyphens: auto">
      <div style="margin-bottom: 26px">
        <h4 data-translate="start.quickNav.insuranceValue">{{ t("products.start.quickNav.insuranceValue") }}</h4>
      </div>

      <div style="margin-top: 20px">
        <h3 data-translate="insuranceValue.title">{{ t("products.insuranceValue.title") }}</h3>
      </div>
      <div class="brightfont">
        <p style="margin-top: 20px" data-translate="insuranceValue.text-1">{{ t("products.insuranceValue.text-1") }}</p>
        <p style="margin-top: 20px" data-translate="insuranceValue.text-2">{{ t("products.insuranceValue.text-2") }}</p>
        <p style="margin-top: 20px; margin-bottom: 0" data-translate="insuranceValue.text-3">
          {{ t("products.insuranceValue.text-3") }}
        </p>
        <p data-translate="insuranceValue.text-4" class="no-margin">{{ t("products.insuranceValue.text-4") }}</p>
        <p data-translate="insuranceValue.text-5" class="no-margin">{{ t("products.insuranceValue.text-5") }}</p>
        <p data-translate="insuranceValue.text-6" class="no-margin">{{ t("products.insuranceValue.text-6") }}</p>
      </div>

      <div>
        <button
          type="submit"
          ui-sref="process-form.input({withdrawalProductType: 'building_insurance_values'})"
          data-ui-sref-opts="{reload: 'process-form'}"
          class="btn btn-elegant-black kw-btn-grid"
          id="buildingInsuranceValues"
          data-translate="calc_now"
          (click)="goToInsuranceForm()"
        >
          <mat-icon class="material-symbols-outlined" style="font-size: 20px; vertical-align: middle">
            play_arrow
          </mat-icon>
          <span>{{ t("products.calc_now") }}</span>
        </button>
      </div>
    </div>
    <div class="flexBoxInner img">
      <div class="centerImage centerImgVertically">
        <img src="/assets/images/products/versicherungswerte_BG.svg" style="align-self: center" />
      </div>
    </div>
  </div>
</ng-container>
